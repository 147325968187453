<template>
  <div class="training-page">
    <router-view />
  </div>
</template>

<script lang="ts" setup>

</script>

<style scoped lang='less'>
</style>